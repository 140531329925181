import React, { useMemo } from 'react';
import { urls } from '../../../constans/urls';
import { useMedia } from '../../../hooks/useMedia';
import { useFetchData } from '../../../hooks/useFetchData';
import { setSectionstState } from '../../../state/sectionsState';
import { useGlobalState } from '../../../state/state';
import HeaderUI from '../ui/HeaderUI';
import styles from './Header.module.css';
// import img from '../../../images/logoMoss.png';      delete
// import { routes } from '../../../constans/routes';
import { sections } from '../../../constans/sections';
 
const HeaderContainer = () => {
 
    const [{ show }] = useGlobalState('menu');
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    const matches700 = useMedia('(max-width: 700px)');
 
    useFetchData(sectionsList, urls.sections, setSectionstState);
 
    const headerItems = useMemo(() => () => {
        return sectionsList ? sectionsList
                                    .filter(el=> (el.name).toUpperCase() !== (sections.docs).toUpperCase())
                                    // .concat(
                                    //         {
                                    //             name: <img src={img} alt='Logo' />,
                                    //             href: routes.mosSport,
                                    //             target: '_blank',
                                    //         }
                                    // ) 
                                    : []
    }, [sectionsList]);
 
    const items = sectionsList ? headerItems() : [];
 
    return <HeaderUI mobileScreen={matches700} show={show} classes={styles} headerItems={items} visuallyImpairedVersion={showVisuallyImpairedVersion} />
}
 
export default HeaderContainer