import React, { useCallback } from 'react'
import ActionTypeSelectUI from '../ui/ActionTypeSelectUI';
import styles from './ActionTypeSelect.module.css';

const ActionTypeSelectContainer = (props) => {
    const {setActionType} = props;
  
    const handleShowDocs = useCallback(() => {
      setActionType('DOCS');
    }, [setActionType]);
  
    const handleShowPurchase = useCallback(() => {
      setActionType('PURCHASE');
    }, [setActionType]);
  
    const handleShoeOtherDocs = useCallback(() => {
      setActionType('OTHER_DOCS');
    }, [setActionType]);
  
    return <ActionTypeSelectUI classes={styles}
                               handleShowDocs={handleShowDocs}
                               handleShowPurchase={handleShowPurchase}
                               handleShoeOtherDocs={handleShoeOtherDocs}
                               {...props}/>
  }
  
  export default ActionTypeSelectContainer