import { setGlobalState, initialState } from './state';
 
export const setNewsActiveState = (data, title) => {
  const newNewsState = data.filter(el => (el.name).toUpperCase() === title.toUpperCase()) || [];
 
  setGlobalState('news', oldData => ({
    ...oldData, 
    activeNewsSection: newNewsState[0]?.active || initialState.news.activeNewsSection,
    orderNewsSection: newNewsState[0]?.order || initialState.news.orderNewsSection
  }));
};
 
export const setNewsContent = data => {
  const newNewsList = data || [];
 
  setGlobalState('news', oldData => ({
    ...oldData, 
    newsList: [ ...(oldData.newsList || []), ...newNewsList ],  
  }));
};
 
export const setCurrentNewsPage = currentPage => {
  setGlobalState('news', oldData => ({
    ...oldData,
    currentNewsPage: currentPage,
  }));
};
 
export const setNewsOffsetState = offset => {
  setGlobalState('news', oldData => ({
    ...oldData,
    newsOffset: offset
  }));
};