import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const SwiperUI = ({ cards, slidesPerView, navigation, breakpoints, autoplay, className }) => {
  return (
      <Swiper navigation={navigation} 
              slidesPerView={slidesPerView} 
              loop={true}
              observer={true}
              observeParents={true}
              spaceBetween={0}
              breakpoints={breakpoints}
              className={className} 
              autoplay={autoplay} > 
        {cards}
      </Swiper>
  )
}

export default SwiperUI
