import React, { useMemo } from 'react';
import { setCurrentNewsPage, setNewsOffsetState } from '../../../../../../../state/newsState';
import { useGlobalState } from '../../../../../../../state/state';
import CardContainer from '../../../../../../common/Card/functional/CardContainer';
import PressCenterCardContainer from '../../../../../../common/PressCenterCard/functional/PressCenterCardContainer';
import NewsUI from '../ui/NewsUI';
import styles from './News.module.css';

const NewsContainer = () => {

    const [{ newsList, newsOffset, newsCount,  currentNewsPage, newsPerPage}] = useGlobalState('news');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    const mapObjects = useMemo(() => ()=>{
        return newsList?.map( el => (
            <CardContainer key={el.id} content={ <PressCenterCardContainer id={el.id} title={el.title} content={el.content} image={el.images} />} />
        ))
    }, [newsList])

    const handlePageClick = ({ selected: selectedPage }) => {
        // const selectedPage = e.selected;
        setCurrentNewsPage(selectedPage);
        const newOffset = selectedPage * newsPerPage;
        setNewsOffsetState(newOffset);
    };

    const currentNewsData = newsList ? mapObjects().slice(newsOffset, newsOffset + newsPerPage) : [];

    return (
        <NewsUI newsCards={currentNewsData} 
                count={newsCount} 
                handlePageClick={handlePageClick} 
                currentPage={currentNewsPage} 
                classes={styles}
                visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}

export default NewsContainer
