import { createGlobalState } from 'react-hooks-global-state';
 
export const initialState = {
  modal: {
    show: false,
    files: null,
  },
  menu: {
    show: false,
  },
  about: {
    aboutList: null,
    content: '',
    director_name: '',
    director_sername: '',
    quote: '',
    director_photo: '',
    team_photo: '',
    address: '',
    work_time: '',
    phone_number: '',
    email: '',
    yandex_maps_link: null,
    activeAboutSection: false,
    orderAboutSection: 0 
  },
  banner: {
    bannerList: null,
  },
  news: {
    newsList: null,
    newsOffset: 0,
    newsPerPage: 9,
    currentNewsPage: 0,
    newsCount: 0,
    activeNewsSection: false,
    orderNewsSection: 0 
  },
  objects: {
    objectsList: null,
    objectsOffset: 0,
    objectsPerPage: 9,
    currentObjectsPage: 0,
    objectsCount: 0,
    activeObjectsSection: false,
    orderObjectsSection: 0 
  },
  docs: {
    docsList: null,
    mainDocsList: [],
    purchaseList: [],
    purchaseOffset: 0,
    purchasePerPage: 20,
    currentPurchasePage: 0,
    purchaseCount: 0,
    otherDocsList: [],
    otherDocsOffset: 0,
    otherDocsPerPage: 18,
    currentOtherDocsPage: 0,
    otherDocsCount: 0,
    orderDocsSection: 0 
  },
  docsDescriptions: {
    docsDescriptionsList: null,
    desc_docs_1: '',
    desc_docs_2: '',
    desc_procurement: '',
  },
  sections: {
    sectionsList: null,
  },
  visuallyImpairedVersion: {
    showVisuallyImpairedVersion: false,
    activeTheme: '',
    activeFontSize: '',
  },

};
 
const { setGlobalState, useGlobalState } = createGlobalState(initialState);
 
export { setGlobalState };
export { useGlobalState };