import React from 'react'

const ForVisuallyImpairedUI = ({ classes, hideForVisuallyImpairedVersion, colorElement, fontSizeElement }) => {
    return (
        <div>
            <section className='Wrapper'>
                <menu className={classes['ForVisuallyImpaired--Menu']}>
                    <div className={classes['ForVisuallyImpaired--Nav-Item']}>
                        <span>Цветовая схема: </span>
                        {colorElement}
                    </div>
                    <div className={classes['ForVisuallyImpaired--Nav-Item']}>
                        <span>Размер шрифтов:</span>
                        {fontSizeElement}
                    </div>
                    <button className={classes['Button']} onClick={hideForVisuallyImpairedVersion}>Обычная версия</button>
                </menu>
            </section>
        </div>
    )
}

export default ForVisuallyImpairedUI
