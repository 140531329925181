import React from 'react';
import { id } from '../functional/FeedbackContainer';

const FeedbackUI = ({ containerClasses, classes, handleChange, onSend, feedbackBody, validation, removeFile }) => {
    return (
        <div className={classes['Feedback-Form']}>
            <p className={`${containerClasses['Subtitle']} ${containerClasses['Subtitle-SupportedBy']}`}>Обратная связь</p>
            <div>
                <lable className={classes['Feedback-Form-Label']}>Имя</lable>
                <input
                    name='name'
                    className={classes['Feedback-Form-Input']}
                    onChange={handleChange}
                    type='text'
                    value={feedbackBody.name}
                />
                <lable className={classes['Feedback-Form-Validation']}>{validation.name}</lable>
            </div>
            <div>
                <lable className={classes['Feedback-Form-Label']}>Тема</lable>
                <input
                    name='subject'
                    className={classes['Feedback-Form-Input']}
                    onChange={handleChange}
                    type='text'
                    value={feedbackBody.subject}
                />
                <lable className={classes['Feedback-Form-Validation']}>{validation.subject}</lable>
            </div>
            <div>
                <lable className={classes['Feedback-Form-Label']}>Обращение</lable>
                <textarea
                    name='text'
                    className={classes['Feedback-Form-Textarea']}
                    onChange={handleChange} rows="5"
                    value={feedbackBody.text}
                />
                <lable className={classes['Feedback-Form-Validation']}>{validation.text}</lable>
            </div>
            <div>
                <lable className={classes['Feedback-Form-Label']}>Адрес электронной почты</lable>
                <input
                    name='email'
                    className={classes['Feedback-Form-Input']}
                    onChange={handleChange}
                    type='email'
                    value={feedbackBody.email}
                />
                <lable className={classes['Feedback-Form-Validation']}>{validation.email}</lable>
            </div>
            <div>
                <lable className={classes['Feedback-Form-Label']}>Телефон</lable>
                <input
                    name='phoneNumber'
                    className={classes['Feedback-Form-Input']}
                    onChange={handleChange}
                    type='number'
                    value={feedbackBody.phoneNumber}
                />
                <lable className={classes['Feedback-Form-Validation']}>{validation.phoneNumber}</lable>
            </div>
            <div className={classes['Feedback-Form-Item']}>
                <lable className={classes['Feedback-Form-Label']}>Вложения</lable>
                <input
                    id='files'
                    name='files'
                    className={`${classes['Feedback-Form-Input']} ${classes['Feedback-Form-FileUpload']}`}
                    onChange={handleChange}
                    type='file'
                    multiple
                />
                {feedbackBody.files.map(x =>
                    <div
                        key={id()}
                        className={`${classes['Feedback-Form-FileName']} ${classes['Feedback-Form-Textarea']}`}
                        onClick={() => removeFile(x)}>
                        {x.name}
                    </div>
                )}
            </div>
            <div className={classes['Feedback-Form-Item']}>
                <lable className={classes['Feedback-Form-Label']}>Получить ответ</lable>
                <input
                    name='isGetAnswer'
                    className={`${classes['Feedback-Form-Input']} ${classes['Feedback-Form-Textarea']} ${classes['Feedback-Form-Input--Checkbox']}`}
                    onChange={handleChange}
                    type='checkbox'
                    value={feedbackBody.isGetAnswer}
                />
            </div>
            <button onClick={onSend} className={classes['Feedback-From-Button']} type='button'>Отправить</button>
        </div>
    )
}

export default FeedbackUI