import React from 'react';
import { useGlobalState } from '../../../../../../../state/state';
import MapUI from '../ui/MapUI';

const MapContainer = () => {

    const [{ yandex_maps_link }] = useGlobalState('about');

    return <MapUI href={yandex_maps_link} />
}

export default MapContainer
