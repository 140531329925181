import React from 'react';
import DocFileUI from '../ui/DocFileUI';
import styles from './DocFile.module.css';

const DocFileContainer = ({href, text, className}) => {
    return (
        <DocFileUI className={className} href={href} text={text} classes={styles} />
    )
}

export default DocFileContainer
