import React, { memo }  from 'react';
import FileImageContainer from '../../FileImage/functional/FileImageContainer';

const DocFileUI = ({href, text, classes, className}) => {
    return (
            <a className={`${classes['FileName']} ${classes[className]}`} href={href} target="_blank" rel="noreferrer">
                <FileImageContainer />
                <span className={classes['FileText']}>{text}</span>
            </a>
    )
}

export default memo(DocFileUI);
