export const swiperBreakpoints = {
    200: {
        slidesPerView: 1,
    },
    600: {
        slidesPerView: 2,
    },
    850: {
        slidesPerView: 3,
    },
    1450: {
        slidesPerView: 4,
    }
}