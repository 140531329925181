import React, { useCallback, useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useGlobalState } from '../../../../state/state';
import PressCenterCardUI from '../ui/PressCenterCardUI';
import styles from './PressCenterCard.module.css';

const PressCenterCardContainer = ({title, content, image, id, hideLink}) => {

    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');

    const mapImages = useMemo(() => () => {
        
        return image?.map(el => <SwiperSlide className={styles['Slide']} key={el.id}>
            <img className={hideLink ? styles['NewsCard-Image--HideLink'] :styles['NewsCard-Image']} src={el.img} alt='NewsImage' />
        </SwiperSlide>)
    }, [image, hideLink])

    const images = image ? mapImages() : [];

    const windowToTop = useCallback(()=>window.scrollTo(0, 0), [])

    return <PressCenterCardUI classes={styles}
                              title={title} 
                              content={content}
                              id={id}
                              images={images}
                              hideLink={hideLink} 
                              onClick={windowToTop}
                              visuallyImpairedVersion={showVisuallyImpairedVersion} />
}

export default PressCenterCardContainer
