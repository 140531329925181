import React from 'react';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setAboutUsContent } from '../../../../../state/aboutState';
import { useGlobalState } from '../../../../../state/state';
import ContactsPageUI from '../ui/ContactsPageUI';

const ContactsPageContainer = () => {

    const [{ aboutList, activeAboutSection }] = useGlobalState('about');
    useFetchData(aboutList, urls.about, setAboutUsContent, activeAboutSection);

    return (
        <ContactsPageUI data={aboutList} dataDep={[aboutList]}/>
    )
}

export default ContactsPageContainer
