import React from 'react'
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer'

const DocsMainUI = ({ docsFirstPart, docsSecondPart, classes, descDocs1, descDocs2 }) => {
    return (
        <div>
            <TitleContainer text='Документы' />
            <p className={classes['Text']}>{descDocs1}</p>
            <div className={classes['Container']}>
                {docsFirstPart}
            </div>
            <p className={`${classes['Text']} ${classes['Text-With-Line']}`}>{descDocs2}</p>
            <div className={classes['Container']}>
                {docsSecondPart}
            </div>
        </div>
    )
}

export default DocsMainUI
