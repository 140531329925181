import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import NewsContainer from '../functional/News/functional/NewsContainer';

const PressCenterPageUI = () => {
    return (
        <div className='Wrapper'>
            <NewsContainer />
        </div>
    )
}

export default WithLoading(PressCenterPageUI)
