import { setGlobalState, initialState } from './state';
 
export const setAboutActiveState = (data, title) => {
  const newAboutState = data?.filter(el => (el.name).toUpperCase() === title.toUpperCase()) || [];
 
  setGlobalState('about', oldData => ({
    ...oldData, 
    activeAboutSection: newAboutState[0]?.active || initialState.about.activeAboutSection,
    orderAboutSection: newAboutState[0]?.order || initialState.about.orderAboutSection
  }));
};
 
export const setAboutUsContent = data => {
    const newAboutList = data || [];
 
    setGlobalState('about', oldData => ({
      ...oldData, 
      aboutList: [ ...(oldData.aboutList || []), ...newAboutList ], 
      content: data[0]?.content || '',
      director_name: data[0]?.director_name || '',
      director_sername: data[0]?.director_sername || '',
      director_photo: data[0]?.director_photo || '',
      team_photo: data[0]?.team_photo || '',
      address: data[0]?.address || '',
      work_time: data[0]?.work_time || '',
      phone_number: data[0]?.phone_number || '',
      email: data[0]?.email || '',
      yandex_maps_link: data[0]?.yandex_maps_link || null,  
      quote: data[0]?.quote || '',
    }));
};