import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { themes, fontSizes } from '../../../constans/visuallyImpairedVersionStyles';
import { hideForVisuallyImpairedVersion, setActiveFontSize, setActiveTheme } from '../../../state/visuallyImpairedVersionState';
import ForVisuallyImpairedUI from '../ui/ForVisuallyImpairedUI';
import styles from './ForVisuallyImpaired.module.css';

const ForVisuallyImpairedContainer = () => {

    const [colorEl, setColorEl] = useState(null);
    const [fontSizeEl, setFontSizeEl] = useState(null);
   
    useEffect(() => {
        setColorEl(themes);
        setFontSizeEl(fontSizes);
        setActiveTheme('White-Black--Theme'); 
        setActiveFontSize('Small--FontSize');
    }, []);

    const activeTheme = useCallback((theme)=>{
        setActiveTheme(theme); 
    }, []);

    const activeFontSize = useCallback((fontSize)=>{
        setActiveFontSize(fontSize);
    }, []);

    const colorElement = useMemo(() => colorEl && colorEl.map((item, i) => {
        return <div key={i} className={`${styles['ForVisuallyImpaired--Color-Item']} ${item}-ColorEl`} onClick={() => activeTheme(item)}>C</div>
       }
       
     ), [colorEl]);

    const fontSizeElement = useMemo(() => fontSizeEl && fontSizeEl.map((item, i) => {
        return <div key={i} className={`${styles['ForVisuallyImpaired--FontSize-Item']} ${item}-FontSizeEl`} onClick={()=>activeFontSize(item)}>A</div>
       }
       
    ), [fontSizeEl]); 

    return (
        <ForVisuallyImpairedUI classes={styles} 
                               hideForVisuallyImpairedVersion={hideForVisuallyImpairedVersion} 
                               colorElement={colorElement} 
                               fontSizeElement={fontSizeElement} />
    )
}

export default ForVisuallyImpairedContainer
