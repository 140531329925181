import axios from 'axios';

const API = axios.create({
  baseURL: "https://api.mossportrazvitie.ru/",
  headers: {
    "Content-Type": "application/json",
  }
});

export default API;
