export const routes = {
    main: '/',
    about: '/about',
    objects: '/objects',
    pressCenter: '/press-center',
    contacts: '/contacts',
    docs: '/docs',
    mosSport: '/mosSport',
    section: '/section',
};
  