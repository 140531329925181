import React from 'react';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setNewsContent } from '../../../../../state/newsState';
import { useGlobalState } from '../../../../../state/state';
import PressCenterPageUI from '../ui/PressCenterPageUI';

const PressCenterPageContainer = () => {

    const [{ newsList }] = useGlobalState('news');
    useFetchData(newsList, urls.pressCenter, setNewsContent);

    return (
        <PressCenterPageUI data={newsList} dataDep={[newsList]} />
    )
}

export default PressCenterPageContainer
