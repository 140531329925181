import React from 'react';
import { urls } from '../../../constans/urls';
import { useFetchData } from '../../../hooks/useFetchData';
import { setSectionstState } from '../../../state/sectionsState';
import { useGlobalState } from '../../../state/state';
import AppUI from '../ui/AppUI';
import './App.css';
 
const AppContainer = () => {
 
    const [{ sectionsList }] = useGlobalState('sections');
    const [{showVisuallyImpairedVersion, activeTheme, activeFontSize}] = useGlobalState('visuallyImpairedVersion');

    useFetchData(sectionsList, urls.sections, setSectionstState);

    return <AppUI data={sectionsList} 
                  dataDep={[sectionsList]} 
                  visuallyImpairedVersion={showVisuallyImpairedVersion}
                  activeFontSize={activeFontSize}
                  activeTheme={activeTheme} />
}
 
export default AppContainer