import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import ContactsSectionContainer from '../../ContactsSection/functional/ContactsSectionContainer';

const ContactsPageUI = () => {
    return (
        <div className='Wrapper'>
            <ContactsSectionContainer title='Контакты' />
        </div>
    )
}

export default WithLoading(ContactsPageUI)
