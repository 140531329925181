import React, { memo } from 'react'

const ActionTypeSelectUI = ({classes, handleShowDocs, actionType, handleShowPurchase, handleShoeOtherDocs}) => {
    const active = classes['ActionTypes-Item_active'];
    return <div className={classes.ActionTypes}>
      <button
        onClick={handleShowDocs}
        className={`${classes['ActionTypes-Item']} 
        ${actionType === 'DOCS' ? active  : ''}`}>Документы</button>
      <button
        onClick={handleShowPurchase}
        className={`${classes['ActionTypes-Item']}
        ${actionType === 'PURCHASE' ? active : ''}`}>Закупки</button>
      <button
        onClick={handleShoeOtherDocs}
        className={`${classes['ActionTypes-Item']}
        ${actionType === 'OTHER_DOCS' ? active : ''}`}>Иные документы</button>
    </div>;
}

export default memo(ActionTypeSelectUI)
