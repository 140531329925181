import React from "react";
import ImageWithDescriptionContainer from "../../../../common/ImageWithDescription/functional/ImageWithDescriptionContainer";
import TitleContainer from "../../../../common/Title/functional/TitleContainer";
import iconPdf from "../../../../../images/iconPdf.png";
import { useMedia } from "../../../../../hooks/useMedia";
import Treats from "../../../../../images/logo-treats.svg"
const AboutSectionUI = ({
  classes,
  text,
  directorPhoto,
  directorName,
  directorSername,
  commonImg,
  quote = "",
  title,
  active,
  showVisuallyImpairedVersion,
}) => {
  const matches600 = useMedia("(max-width: 600px)");

  return (
    <>
      {active && (
        <section className={showVisuallyImpairedVersion ? "Wrapper" : ""}>
          <TitleContainer text={title} />
          <div
            className={`${classes["Container"]} ${quote
              ? classes["Container-AboutUsPage"]
              : classes["Container-MainPage"]
              }`}
          >
            <p className={classes["Text"]}>{text}</p>
            {/* <ImageWithDescriptionContainer directorPhoto={directorPhoto}
                        directorName={directorName}
                        directorSername={directorSername}
                        className='Description' /> */}
            {/* {quote && <p className={classes['Quote']}>{quote}</p>} */}
            {/*<img src={commonImg} className={classes['Image']} alt='commonImg' />*/}
            <div className={classes["Media-block"]}>
              <img src={Treats} />
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/EyH5yG4-mdQ?si=E9mmgeYHMAU2Y-5E"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen>

              </iframe>
            </div>

            {matches600 ? (
              <div className={classes["Files"]}>
                <a
                  href="files/Отчет_о_проведении_специальной_оценки_условий_труда.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Отчет о проведении специальной оценки условий труда.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Отчет о проведении специальной оценки условий труда.pdf
                </a>
                <a
                  href="files/Сводная_ведомость_результатов_проведения_СОУТ.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Сводная ведомость результатов проведения СОУТ.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Сводная ведомость результатов проведения СОУТ.pdf
                </a>
                <a
                  href="files/Титульный_лист_СОУТ.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Титульный лист СОУТ.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Титульный лиsст СОУТ.pdf
                </a>
                <a
                  href="files/ПБ_в_квартире.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ПБ_в_квартире.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ПБ в квартире.pdf
                </a>
                <a
                  href="files/ПБ_на_природе.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ПБ_на_природе.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ПБ на природе.pdf
                </a>
                <a
                  href="files/ОБ_поведения_на_водоёмах_летом.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ОБ_поведения_на_водоёмах_летом.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ОБ поведения на водоёмах летом.pdf
                </a>
              </div>
            ) : (
              <div className={classes["Files"]}>
                <a
                  href="files/Отчет_о_проведении_специальной_оценки_условий_труда.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Отчет о проведении специальной оценки условий труда.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Отчет о прове...да.pdf
                </a>
                <a
                  href="files/Сводная_ведомость_результатов_проведения_СОУТ.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Сводная ведомость результатов проведения СОУТ.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Сводная ведом...УТ.pdf
                </a>
                <a
                  href="files/Титульный_лист_СОУТ.pdf"
                  download
                  className={classes["IconLink"]}
                  title="Титульный лист СОУТ.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  Титульный лист СОУТ.pdf
                </a>
                <a
                  href="files/ПБ_в_квартире.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ПБ_в_квартире.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ПБ в квартире.pdf
                </a>
                <a
                  href="files/ПБ_на_природе.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ПБ_на_природе.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ПБ на природе.pdf
                </a>
                <a
                  href="files/ОБ_поведения_на_водоёмах_летом.pdf"
                  download
                  className={classes["IconLink"]}
                  title="ОБ_поведения_на_водоёмах_летом.pdf"
                >
                  <img src={iconPdf} alt="icon" className={classes["Icon"]} />
                  ОБ поведения на водоёмах летом.pdf
                </a>
              </div>
            )}
          </div>
          <br />
        </section>
      )}
    </>
  );
};

//Отчет о проведении специальной оценки условий труда

export default AboutSectionUI;
