import React, {useEffect} from 'react'
import { sections } from '../../../../../constans/sections';
import { setAboutActiveState } from '../../../../../state/aboutState';
import { useGlobalState } from '../../../../../state/state';
import AboutSectionUI from '../ui/AboutSectionUI';
import styles from './AboutSection.module.css';
 
const AboutSectionContainer = ({quote}) => {
 
    const [{ content, director_name, director_sername, director_photo, team_photo, activeAboutSection }] = useGlobalState('about');
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    useEffect(()=>{
        if(sectionsList){
            setAboutActiveState(sectionsList, sections.about);
        }
    },[sectionsList]);
    
    return (
        <AboutSectionUI text={content} 
                        directorPhoto={director_photo}
                        directorName={director_name}
                        directorSername={director_sername}
                        commonImg={team_photo}
                        classes={styles} 
                        quote={quote} 
                        title={sections.about} 
                        active={activeAboutSection}
                        showVisuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}
 
export default AboutSectionContainer
