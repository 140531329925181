import React from 'react';
import NavigationContainer from '../../Navigation/functional/NavigationContainer';

const FooterUI = ({ classes, items, showForVisuallyImpairedVersion, visuallyImpairedVersion }) => {

    return (
        <>
        {visuallyImpairedVersion ? 
        (
            <footer>
                <section className='Wrapper'>
                    <p className={classes['Copyright--VisuallyImpairedVersion']}>© 2021 Автономная некоммерческая организация ”Московский центр развития спортивной инфраструктуры”</p>
                </section>
            </footer>
        )
        :
        (
            <footer className={classes['Footer']}>
            <div className={classes['Footer-Container']}>
                <div>
                    <NavigationContainer items={items} className={items?.length <= 4 ? 'Footer-Navigation--Column-4' : 'Footer-Navigation--Column-3'} />
                    <div onClick={showForVisuallyImpairedVersion} className={classes['Button']}>Версия для слабовидящих</div>
                </div>
                <div>
                    <p className={classes['Copyright']}>© 2021 Автономная некоммерческая организация</p>
                    <p className={classes['Copyright']}>”Московский центр развития спортивной инфраструктуры”</p>
                </div>
            </div>
            </footer>
        )
        }
    </>
    )
}

export default FooterUI