import React, { useMemo } from 'react';
import { useGlobalState } from '../../../../../../../state/state';
import DocFileContainer from '../../../../../../common/DocFile/functional/DocFileContainer';
import DocsMainUI from '../ui/DocsMainUI';
import styles from './DocsMain.module.css';

const DocsMainContainer = () => {

    const [{ mainDocsList }] = useGlobalState('docs');
    const [{ desc_docs_1, desc_docs_2 }] = useGlobalState('docsDescriptions');

    const mapMainDocsFiles = useMemo(() => ()=>{
        return mainDocsList?.map( el => <DocFileContainer key={el.id} href={el.file} text={el.name} /> )
    }, [mainDocsList])

    const docs = mainDocsList ? mapMainDocsFiles() : [];

    const docsFirstPart = docs ? docs.slice(0,6) : [];
    
    const docsSecondPart = docs ? docs.slice(6, 12) : [];

    return (
        <DocsMainUI docsFirstPart={docsFirstPart} docsSecondPart={docsSecondPart} classes={styles} descDocs1={desc_docs_1} descDocs2={desc_docs_2}  />
    )
}

export default DocsMainContainer
