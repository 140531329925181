import React from 'react'
import WithLoading from '../../../../../hoc/withLoading'

const PressCenterIdUI = ({ newsCards, newsCard, classes, visuallyImpairedVersion }) => {
    return (
        <div>
            <section className={visuallyImpairedVersion ? 'Wrapper' : ''}>
                <div className={visuallyImpairedVersion ? classes['Container--VisuallyImpairedVersion'] : classes['Container']}>
                    <div>
                        {newsCard}
                    </div>
                    <div className={classes['OtherCards-Wrapper']}>
                        <p className={classes['OtherCards-Title']}>ДРУГИЕ НОВОСТИ:</p>
                        {newsCards}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WithLoading(PressCenterIdUI)
