import React, {useState, useEffect, useMemo} from "react";
import {NavLink, useLocation} from "react-router-dom";
import NavigationUI from "../ui/NavigationUI";
import styles from "./Navigation.module.css";
 
const NavigationContainer = ({className, items, close}) => {
  
  const [navigation, setNavigation] = useState(null);
 
  const {pathname} = useLocation();
 
  useEffect(() => {
    setNavigation(items);
  }, [pathname, items]);
 
  const navigationElement = useMemo(() => navigation && navigation.map((item, i) => {
   return <NavLink to={item.href} key={i} target={item.target}
             activeClassName={styles['Link-Active']}
             className={styles['Link-Undecorated']}>
      <li className={styles['Navbar-Item']} onClick={close}>
        {item.name}
      </li> 
    </NavLink>
  }
  
), [navigation, close]);
 
  return <NavigationUI classes={styles}
                       navigationElement={navigationElement}
                       className={className} />;
}
 
export default NavigationContainer;