import React, { useMemo } from 'react';
import { useGlobalState } from '../../../state/state';
import {closeModal} from '../../../state/modalState';
import ModalUI from '../ui/ModalUI';
import styles from './Modal.module.css';
import DocFileContainer from '../../common/DocFile/functional/DocFileContainer';

const ModalContainer = () => {

    const [{ show, files, text }] = useGlobalState('modal');

    const mapFiles = useMemo(() => ()=>{
        return files?.map( el => <DocFileContainer className='Column' key={el.id} href={el.file} text={el.name} /> )
    }, [files])

    const docs = files ? mapFiles() : [];

    return (
        <ModalUI closeModal={closeModal}
                 show={show} 
                 classes={styles}
                 text={text}
                 docs={docs} />
    )
}

export default ModalContainer
