import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setObjectsContent } from '../../../../../state/objectsState';
import { useGlobalState } from '../../../../../state/state';
import CardContainer from '../../../../common/Card/functional/CardContainer';
import ObjectCardContainer from '../../../../common/ObjectCard/functional/ObjectCardContainer';
import ObjectIdUI from '../ui/ObjectIdUI';
import styles from './ObjectId.module.css';
 
const ObjectIdContainer = () => {
 
    const { objectId } = useParams();
 
    const [{ objectsList }] = useGlobalState('objects');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    useFetchData(objectsList, urls.objects, setObjectsContent);
 
    const shortObjectsList = useMemo(()=>{
        return objectsList ? objectsList.slice(objectsList.length-3, objectsList.length) : [];
    }, [objectsList])
 
    const filteredObject = useMemo(() => () => {
 
        let filteredObject = objectsList?.filter( el => el.id === Number(objectId));
         
         return filteredObject.map( el => (
            <ObjectCardContainer key={el.id} id={el.id} content={el.content} title={el.title} address={el.address} image={el.images} hideLink />
        ))
    }, [objectsList, objectId])
 
    const currentObject = objectsList ? filteredObject() : [];
 
    const mapObjectsCards = useMemo(() => ()=>{
        return shortObjectsList?.map( el => (
            <CardContainer key={el.id} margin content={ <ObjectCardContainer id={el.id} title={el.title} image={el.images} />} />
        ) )
    }, [shortObjectsList])
 
    const otherCards = shortObjectsList ? mapObjectsCards() : [];
 
    return (
        <ObjectIdUI objectCard={currentObject} 
                    otherCards={otherCards} 
                    classes={styles} 
                    data={objectsList} 
                    dataDep={[objectsList]}
                    visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}
 
export default ObjectIdContainer