import React from 'react';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setAboutUsContent } from '../../../../../state/aboutState';
import { useGlobalState } from '../../../../../state/state';
import AboutPageUI from '../ui/AboutPageUI';

const AboutPageContainer = () => {

    const [{ aboutList, activeAboutSection }] = useGlobalState('about');
    useFetchData(aboutList, urls.about, setAboutUsContent, activeAboutSection);

    return (
        <AboutPageUI data={aboutList} dataDep={[aboutList]} />
    )
}

export default AboutPageContainer;
