import React from "react";
import { useGlobalState } from "../../../../../../../state/state";
import AboutSectionContainer from "../../../../AboutSection/functional/AboutSectionContainer";

const AboutUsUI = () => {
  const [{ quote }] = useGlobalState("about");

  return <AboutSectionContainer quote={quote} />;
};

export default AboutUsUI;
