import React from 'react';
import PaginateContainer from '../../../../../../common/Paginate/functional/PaginateContainer';
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer';

const ObjectsUI = ({ objectsCards, count, handlePageClick, currentPage, classes, visuallyImpairedVersion }) => {
    return (
        <section className={visuallyImpairedVersion ? 'Wrapper' : ''}>
            <TitleContainer text='ОБЪЕКТЫ' />
            <div className={visuallyImpairedVersion ? classes['Container--VisuallyImpairedVersion']: classes['Container']}>
                {objectsCards}
            </div>
            {!visuallyImpairedVersion && <PaginateContainer count={count} handlePageClick={handlePageClick} currentPage={currentPage} />}
        </section>
    )
}

export default ObjectsUI
