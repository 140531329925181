import { setGlobalState, initialState } from './state';
 
export const setObjectsActiveState = (data, title) => {
  const newObjectsState = data.filter(el => (el.name).toUpperCase() === title.toUpperCase()) || [];
 
  setGlobalState('objects', oldData => ({
    ...oldData, 
    activeObjectsSection: newObjectsState[0]?.active || initialState.objects.activeObjectsSection,
    orderObjectsSection: newObjectsState[0]?.order || initialState.objects.orderObjectsSection
  }));
};
 
export const setObjectsContent = data => {
    const newObjectsList = data || [];
 
    setGlobalState('objects', oldData => ({
      ...oldData, 
      objectsList: [ ...(oldData.objectsList || []), ...newObjectsList ]
    }));
};
 
export const setCurrentObjectsPage = currentPage => {
  setGlobalState('objects', oldData => ({
    ...oldData,
    currentObjectsPage: currentPage,
  }));
};
 
export const setObjectsOffsetState = offset => {
  setGlobalState('objects', oldData => ({
    ...oldData,
    objectsOffset: offset
  }));
};