import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import ObjectsMapContainer from '../../../../common/ObjectsMap/functional/ObjectsMapContainer';
import AboutSectionContainer from '../../AboutSection/functional/AboutSectionContainer';
import BannerContainer from '../functional/Banner/functional/BannerContainer';
import ObjectsSectionContainer from '../functional/ObjectsSection/functional/ObjectsSectionContainer';
import PressCenterSectionContainer from '../functional/PressCenterSection/functional/PressCenterSectionContainer';

const MainPageUI = ({ classes, orderObjectsSection, orderNewsSection, orderAboutSection }) => {

    return (
        <div className={classes['Container']}>
            <BannerContainer />
                <div className={ orderAboutSection ? classes[`FlexOrder--${orderAboutSection}`] : classes[`FlexOrder--1`]}>
                    <AboutSectionContainer />
                </div>
                <div className={ orderNewsSection ? classes[`FlexOrder--${orderNewsSection}`] : classes[`FlexOrder--2`]}>
                    <PressCenterSectionContainer />
                </div>
                <div className={orderObjectsSection ? classes[`FlexOrder--${orderObjectsSection}`] : classes[`FlexOrder--3`]}>
                    <ObjectsSectionContainer />
                </div>
                <div className={orderObjectsSection ? classes[`FlexOrder--${orderObjectsSection}`] : classes[`FlexOrder--4`]}>
                    <ObjectsMapContainer />
                </div>
            </div>
    )
}

export default WithLoading(MainPageUI)
