import React from 'react';
import Modal from 'react-modal';
import TitleContainer from '../../common/Title/functional/TitleContainer';

const ModalUI = ({ closeModal, show, classes, docs, text }) => {
    return (
        <Modal
            isOpen={show}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className={classes['Modal']}
            overlayClassName={classes['Modal-Overlay']}
        >
            <TitleContainer text={text} />
            <div className={classes['Container']}>
                {docs}
            </div>
        </Modal>
    )
}

export default ModalUI
