export const urls = {
    banner: '/api/main_image/',
    about: '/api/about-us/',
    objects: '/api/object/',
    pressCenter: '/api/news/',
    docs: '/api/docs/',
    docsDescriptions: '/api/docs_descriptions',
    sections: '/api/section',
    depMosSport: 'https://www.mos.ru/moskomsport/',
};
  