import React, { useMemo } from 'react'
import { setCurrentPurchasePage, setPurchaseOffsetState } from '../../../../../../../state/docsState';
import { useGlobalState } from '../../../../../../../state/state';
import DocFileContainer from '../../../../../../common/DocFile/functional/DocFileContainer';
import RenderDirsContainer from '../../RenderDirs/functional/RenderDirsContainer';
import PurchaseUI from '../ui/PurchaseUI';
import styles from './Purchase.module.css';

const PurchaseContainer = () => {

    const [{ purchaseList, purchaseOffset, purchaseCount,  currentPurchasePage, purchasePerPage}] = useGlobalState('docs');
    const [{ desc_procurement }] = useGlobalState('docsDescriptions');

    const mapPurchaseDocsFiles = useMemo(() => ()=>{
        return purchaseList?.map( (el, i) => {
            return el.folder === null ? 
                <DocFileContainer key={i} href={el.file} text={el.name} className='Purchase' />
            :
                <RenderDirsContainer key={i} text={el[0]?.folder?.name} files={el} />
        })
    }, [purchaseList])

    const handlePageClick = ({ selected: selectedPage }) => {
        // const selectedPage = e.selected;
        setCurrentPurchasePage(selectedPage);
        const newOffset = selectedPage * purchasePerPage;
        setPurchaseOffsetState(newOffset);
    };

    const currentPageData = purchaseList ? mapPurchaseDocsFiles().slice(purchaseOffset, purchaseOffset + purchasePerPage) : [];

    return (
            <PurchaseUI classes={styles} 
                        dirs={currentPageData} 
                        handlePageClick={handlePageClick} 
                        currentPage={currentPurchasePage} 
                        count={purchaseCount} 
                        description={desc_procurement}/>
    )
}

export default PurchaseContainer
