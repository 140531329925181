import React from 'react'
import DirImageContainer from '../../../../../../common/DirImage/functional/DirImageContainer'

const RenderDirsUI = ({ text, onClick, classes}) => {
    return (
        <div className={classes['Container']} onClick={onClick}>
            <DirImageContainer />
            <p className={classes['Text']}>{text}</p>
        </div>
    )
}

export default RenderDirsUI
