export const themes = [
    'White-Black--Theme',
    'Black-White--Theme',
    'Black-Yellow--Theme',
    'Blue-Black--Theme',
];

export const fontSizes = [
    'Small--FontSize',
    'Medium--FontSize',
    'Big--FontSize',
];