import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginateUI = ({count, handlePageClick, currentPage}) => {
    return (
        <>
        { count > 0 &&
            <ReactPaginate
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={(pageNumber) => handlePageClick(pageNumber)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            forcePage={currentPage}
            activeClassName={"active"} />
        }
        </>
    )
}

export default PaginateUI
