import React, { useMemo, useState } from 'react'
import DocsPageUI from '../ui/DocsPageUI';
import DocsMainContainer from './DocsMain/functional/DocsMainContainer';
import OtherDocsContainer from './OtherDocs/functional/OtherDocsContainer';
import PurchaseContainer from './Purchase/functional/PurchaseContainer';
import { useGlobalState } from '../../../../../state/state';
import { urls } from '../../../../../constans/urls';
import { setDocsContent } from '../../../../../state/docsState';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setDocsDescriptionsContent } from '../../../../../state/docsDescriptionsState';

const DocsPageContainer = () => {

  const [{ docsList }] = useGlobalState('docs');
  const [{ docsDescriptionsList }] = useGlobalState('docsDescriptions');

  useFetchData(docsList, urls.docs, setDocsContent);
  useFetchData(docsDescriptionsList, urls.docsDescriptions, setDocsDescriptionsContent);

  const [actionType, setActionType] = useState('DOCS');

  const renderComponent = useMemo(() => {
    switch (actionType) {
      case "DOCS":
        return DocsMainContainer;
      case "PURCHASE":
        return PurchaseContainer;
      case "OTHER_DOCS":
        return OtherDocsContainer;
      default:
        return null;
      }
    }, [actionType])

  return (
    <DocsPageUI RenderComponent={renderComponent} 
                setActionType={setActionType} 
                actionType={actionType} 
                data={docsList} 
                dataDep={[docsList]}/>
  )
}

export default DocsPageContainer
