import React, {memo} from 'react';

const NavigationUI = ({ classes, navigationElement, className = '' }) => {
    return <nav>
        <ul className={`${classes['NavigationList']} ${classes[className]}`}>
            {navigationElement}
        </ul>
    </nav>
}


export default memo(NavigationUI);
