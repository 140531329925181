import React from 'react';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setAboutUsContent } from '../../../../../state/aboutState';
import { setBannerContent } from '../../../../../state/bannerState';
import { setNewsContent } from '../../../../../state/newsState';
import { setObjectsContent } from '../../../../../state/objectsState';
import { useGlobalState } from '../../../../../state/state';
import MainPageUI from '../ui/MainPageUI';
import styles from './MainPage.module.css'
 
const MainPageContainer = () => {
    
    const [{ bannerList }] = useGlobalState('banner');
    const [{ orderObjectsSection, objectsList }] = useGlobalState('objects');
    const [{ orderNewsSection, newsList, activeNewsSection }] = useGlobalState('news');
    const [{ orderAboutSection, aboutList, activeAboutSection, activeObjectsSection }] = useGlobalState('about');
 
    useFetchData(bannerList, urls.banner, setBannerContent);
    useFetchData(aboutList, urls.about, setAboutUsContent, activeAboutSection);
    useFetchData(newsList, urls.pressCenter, setNewsContent, activeNewsSection);
    useFetchData(objectsList, urls.objects, setObjectsContent, activeObjectsSection);
 
    return <MainPageUI classes={styles} 
                orderObjectsSection={orderObjectsSection} 
                orderNewsSection={orderNewsSection} 
                orderAboutSection={orderAboutSection} 
                data = {bannerList && objectsList && newsList && aboutList} 
                dataDep = {[bannerList, aboutList, objectsList, newsList]} />
}
 
export default MainPageContainer