import React from 'react'
 
const CardUI = ({content, margin=false, classes, onClick}) => {
    return (
        <div className={margin ? `${classes['Card']} ${classes['Card-Margin']}` : classes['Card']} onClick={onClick}>
            {content}
        </div>
    )
}
 
export default CardUI