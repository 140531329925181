import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../../../constans/routes';
import AboutPageContainer from '../functional/AboutPage/functional/AboutPageContainer';
import ContactsPageContainer from '../functional/ContactsPage/functional/ContactsPageContainer';
import DocsPageContainer from '../functional/DocsPage/functional/DocsPageContainer';
import MainPageContainer from '../functional/MainPage/functional/MainPageContainer';
import ObjectIdContainer from '../functional/ObjectId/functional/ObjectIdContainer';
import ObjectsPageContainer from '../functional/ObjectsPage/functional/ObjectsPageContainer';
import PressCenterIdContainer from '../functional/PressCenterId/functional/PressCenterIdContainer';
import PressCenterPageContainer from '../functional/PressCenterPage/functional/PressCenterPageContainer';

const ContentUI = () => {
    return (
            <Switch>
                <Route exact path={routes.about} component={AboutPageContainer} />
                <Route exact path={routes.objects} component={ObjectsPageContainer} />
                <Route exact path={routes.pressCenter} component={PressCenterPageContainer} />
                <Route exact path={routes.contacts} component={ContactsPageContainer} />
                <Route exact path={routes.main} component={MainPageContainer} />
                <Route path={routes.docs} component={DocsPageContainer} />
                <Route path={`${routes.pressCenter}/:pressCenterId`} component={PressCenterIdContainer} />
                <Route path={`${routes.objects}/:objectId`} component={ObjectIdContainer} />
                <Redirect to={routes.main} component={MainPageContainer} />
            </Switch>
    )
}

export default ContentUI
