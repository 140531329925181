import React from "react";

import MosSportLogoUI from "../ui/MosSportLogoUI";

import styles from '../../Logo.module.css';

const MosSportLogoContainer = () => {
  return <MosSportLogoUI classes={styles} />;
}

export default MosSportLogoContainer;
