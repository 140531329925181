import React, {memo} from 'react';

const FileImageUI = ({classes}) => {
    return (
        <div className={classes['FileImg']}>
            <svg width="44" height="65" viewBox="0 0 404 593" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M334.754 82.6868V0.84375H68.0089L0.728516 80.3654V510.902H69.9739V592.745H404V82.6868H334.754ZM77.7391 38.3913V91.8647H32.4966L77.7391 38.3913ZM30.0719 126.546H107.082V35.5255H305.41V476.22H30.0719V126.546ZM374.657 558.063H99.3173V510.902H334.754V117.369H374.657V558.063Z" fill="#333333" />
                <path d="M72.2969 161.227H263.191V195.908H72.2969V161.227Z" fill="#333333" />
                <path d="M72.2969 230.584H263.191V265.267H72.2969V230.584Z" fill="#333333" />
                <path d="M72.2969 299.961H263.191V334.643H72.2969V299.961Z" fill="#333333" />
            </svg>
        </div>
    )
}

export default memo(FileImageUI)
