import React from 'react';
import { useGlobalState } from '../../../../../state/state';
import ContactsSectionUI from '../ui/ContactsSectionUI';
import styles from './ContactsSection.module.css';
import logo from '../../../../../images/logoMoss.png';
 
const ContactsSectionContainer = ({title, subtitle}) => {
 
    const [{ address, work_time, phone_number, email}] = useGlobalState('about');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    return (
        <ContactsSectionUI title={title} 
                           classes={styles} 
                           adress={address} 
                           workTime={work_time} 
                           phoneNumber={phone_number} 
                           email={email} 
                           logo={logo} 
                           subtitle={subtitle}
                           visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}
 
export default ContactsSectionContainer