import React, { useMemo } from 'react';
import { useGlobalState } from '../../../../../../../state/state';
import BannerUI from '../ui/BannerUI';
import { SwiperSlide } from 'swiper/react';
import styles from './Banner.module.css';

const BannerContainer = () => {

    const [{ bannerList }] = useGlobalState('banner');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');

    const mapBannerCards = useMemo(() => () => {
        return bannerList?.map(el => <SwiperSlide key={el.id}>
            {/* <img className={styles.Baner} src={el.image} alt='Banner' /> */}
            <div className={styles.Baner} style={{backgroundImage: `url(${el.image})`}}>
                { el.name && <div className={styles['Baner-Content']}>
                    <p className={styles['Baner-ObjectSubtitile']}>{el.object_type}</p>
                    <p className={styles['Baner-ObjectTitile']}>{el.name}</p>
                    <p className={styles['Baner-ObjectItem']}>{el.address}</p>
                    <p className={styles['Baner-ObjectItem']}>{el.description}</p>
                </div>}
            </div>
        </SwiperSlide>)
    }, [bannerList])

    const bannerCards = bannerList ? mapBannerCards() : [];

    return !showVisuallyImpairedVersion && <BannerUI cards={bannerCards} slidesPerView='1' />
}

export default BannerContainer
