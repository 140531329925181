import React from 'react';
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer';
import PaginateContainer from '../../../../../../common/Paginate/functional/PaginateContainer';

const PurchaseUI = ({dirs, classes, handlePageClick, count, currentPage, description}) => {
    return (
        <div>
            <TitleContainer text='Закупки' />
            <p className={classes['Text']}>{description}</p>
            <div className={classes['Container']}>
            {dirs}
            </div>
          <PaginateContainer count={count} handlePageClick={handlePageClick} currentPage={currentPage} />
        </div>
    )
}

export default PurchaseUI
