import React from 'react';
import RenderDirsUI from '../ui/RenderDirsUI';
import {openModal} from '../../../../../../../state/modalState';
import styles from './RenderDirs.module.css';

const RenderDirsContainer = ({text, files}) => {
    return (
        <RenderDirsUI classes={styles} text={text} onClick={()=>openModal({ files: files, text: text })} />
    )
}

export default RenderDirsContainer
