import React, { useMemo, useEffect } from 'react';
import { setObjectsActiveState } from '../../../../../../../state/objectsState';
import { useGlobalState } from '../../../../../../../state/state';
import CardContainer from '../../../../../../common/Card/functional/CardContainer';
import ObjectCardContainer from '../../../../../../common/ObjectCard/functional/ObjectCardContainer';
import ObjectsSectionUI from '../ui/ObjectsSectionUI';
import styles from './ObjectsSection.module.css';
import { sections } from '../../../../../../../constans/sections';

const ObjectsSectionContainer = () => {

    const [{ objectsList, activeObjectsSection }] = useGlobalState('objects');
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');

    useEffect(() => {
        if (sectionsList) {
            setObjectsActiveState(sectionsList, sections.objects);
        }
    }, [sectionsList]);

    const shortObjectsList = useMemo(() => {
        return objectsList ?
            objectsList.length > 4 ? objectsList.slice(objectsList.length - 4, objectsList.length) : objectsList
            :
            [];
    }, [objectsList])

    const mapObjectsCards = useMemo(() => () => {

        return shortObjectsList?.map(el => (
            <CardContainer key={el.id} content={<ObjectCardContainer id={el.id} title={el.title} address={el.address} image={el.images} />} />
        ))
    }, [shortObjectsList])

    const objectsCards = shortObjectsList ? mapObjectsCards() : [];

    return <ObjectsSectionUI classes={styles} 
                             cards={objectsCards} 
                             title={sections.objects} 
                             active={activeObjectsSection}
                             visuallyImpairedVersion={showVisuallyImpairedVersion} />
}

export default ObjectsSectionContainer