import React from 'react';
import SwiperUI from '../ui/SwiperUI';
import './Swiper.css';

const SwiperContainer = ({ cards, slidesPerView, navigation, breakpoints, autoplay, className }) => {

    return (
        <SwiperUI className={className} 
                  breakpoints={breakpoints} 
                  cards={cards} 
                  slidesPerView={slidesPerView} 
                  navigation={navigation} 
                  autoplay={autoplay}/>
    )
}

export default SwiperContainer
