import React, { useMemo, useEffect } from 'react';
import { useGlobalState } from '../../../../state/state';
import ObjectsMapUI from '../ui/ObjectsMapUI';
import { Placemark } from 'react-yandex-maps';
import { setObjectsActiveState } from '../../../../state/objectsState';
import { sections } from '../../../../constans/sections';

const ObjectsMapContainer = () => {

    const [{ objectsList, activeObjectsSection }] = useGlobalState('objects');
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');

    useEffect(()=>{
        if(sectionsList){
            setObjectsActiveState(sectionsList, sections.objects);
        }
    },[sectionsList]);

    const mapObjectsCards = useMemo(() => () => {
        return objectsList?.map(el => (
            <Placemark key={el.id}
                geometry={el.coordinates}
                options={{
                    preset: 'islands#redWaterParkIcon',
                    hasBalloon: true,
                    hasHint: true,
                    openEmptyBalloon: true

                }}
                properties={{
                    balloonContent: `${el.address}`
                }}
                modules={
                    ['geoObject.addon.balloon', 'geoObject.addon.hint']
                }
            />
        ))
    }, [objectsList])

    const objectsCards = objectsList ? mapObjectsCards() : [];

    const mapData = {
        center: [55.764688, 37.719828],
        zoom: 10,
    };

    return !showVisuallyImpairedVersion && <ObjectsMapUI mapData={mapData} placemarks={objectsCards} active={activeObjectsSection} />
}

export default ObjectsMapContainer
