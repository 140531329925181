import React from 'react';
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer';
 
const ObjectsSectionUI = ({ cards, classes, title, active, visuallyImpairedVersion }) => {
    return (
        <>
            { active && <section className={visuallyImpairedVersion ? 'Wrapper': ''}>
                    <TitleContainer text={title} />
                    <div className={visuallyImpairedVersion ? classes['Container-VisuallyImpairedVersion'] : classes['Container']}>
                        {cards}
                    </div>
                </section>
            }
        </>
    )
}
 
export default ObjectsSectionUI