import React, { useMemo } from 'react';
import styles from './ObjectCard.module.css';
import ObjectCardUI from '../ui/ObjectCardUI';
import { SwiperSlide } from 'swiper/react';
import { useGlobalState } from '../../../../state/state';

const ObjectCardContainer = ({ image, title, content, address, id, hideLink, cardContent }) => {

    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');

    const mapImages = useMemo(() => () => {
        return image?.map(el => <SwiperSlide className={styles['Slide']} key={el.id}>
            <img className={hideLink ?
                                    styles['Object-Image--HideLink'] 
                                    :
                                    (address || content ? styles['ObjectCard-Image'] : styles['OtherCard-ObjectImage'])}
                src={el.img} alt='ObjectImage' />
        </SwiperSlide>)
    }, [image, hideLink, address, content])

    const images = image ? mapImages() : [];

    return (
        <ObjectCardUI images={images} 
                      title={title} 
                      content={content} 
                      address={address} 
                      classes={styles} 
                      id={id} 
                      hideLink={hideLink} 
                      cardContent={cardContent}
                      visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}

export default ObjectCardContainer