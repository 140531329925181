import React, { useEffect, useState } from 'react';

function WithLoading(Component) {

  return function WihLoadingComponent({ data, dataDep, ...props }) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      if (data) {
        setLoading(false);
      }
    }, dataDep);

    if (!loading) return (<Component {...props} />);
    
    return (<div className="loader"></div>);
  }
}

export default WithLoading;