import React from 'react';
import PaginateUI from '../ui/PaginateUI';
import './Paginate.css';

const PaginateContainer = ({count, handlePageClick, currentPage}) => {

    return <PaginateUI count={count} handlePageClick={handlePageClick} currentPage={currentPage} />
}

export default PaginateContainer
