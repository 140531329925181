import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.css';
import { routes } from '../../../../constans/routes';
import SwiperContainer from '../../Swiper/functional/SwiperContainer';

const PressCenterCardUI = ({ classes, title, content, images, id, hideLink, onClick, visuallyImpairedVersion }) => {

    return (
        <>
            {
                hideLink ? (
                    <>
                        <SwiperContainer navigation={false} slidesPerView={1} cards={images} />
                        <p></p>
                        <h5 className={classes['Title--HideLink']}>{title}</h5>
                        <p className={classes['Subtitle--HideLink']}>{content}</p>
                    </>
                )
                    : (
                        <Link to={`${routes.pressCenter}/${id}`} className={images.length > 0 ? classes['Slide-Link'] : classes['Slide-Link--OtherNews']}>
                            <div onClick={onClick}>
                                {images && <SwiperContainer navigation={false} slidesPerView={1} cards={images} />}
                                <p></p>
                                {visuallyImpairedVersion ?
                                    <>
                                        <h5 className={classes['Title--VisuallyImpairedVersion']}>{title}</h5>
                                        <p>{content}</p>
                                    </>
                                    :
                                    <>
                                        <h5 className={images.length > 0 ? `${classes['Slide-Text']} ${classes['Title']}` : classes['Title--OtherNews']}>{title}</h5>
                                        <p className={images.length > 0 ? `${classes['Slide-Text']} ${classes['Subtitle']}` : classes['Subtitle--OtherNews']}>{content}</p>
                                    </>
                                }

                            </div>
                        </Link>
                    )
            }
        </>
    )
}

export default memo(PressCenterCardUI)
