import React from 'react';
import { useGlobalState } from '../../../state/state';
import { showForVisuallyImpairedVersion } from '../../../state/visuallyImpairedVersionState';
import FooterUI from '../ui/FooterUI';
import styles from './Footer.module.css';
 
const FooterContainer = () => {
 
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    return <FooterUI classes={styles} 
                     items={sectionsList} 
                     showForVisuallyImpairedVersion={showForVisuallyImpairedVersion}
                     visuallyImpairedVersion={showVisuallyImpairedVersion} />
}
 
export default FooterContainer