import React, {useState} from 'react';
import FeedbackUI from '../ui/FeedbackUI';
import styles from './Feedback.module.css';
import {nanoid} from 'nanoid'
import API from "../../../../../../../api/api";

const initValues = {
    name: '',
    subject: '',
    text: '',
    email: '',
    phoneNumber: '',
    files: [],
    isGetAnswer: false
}

const initValidation = {
    name: '',
    subject: '',
    text: '',
    phoneNumber: '',
    email: '',
}

export const validationMessage = 'Некоректное значение'

const isValidPhoneNumber = (phone) => phone.match('(0|91)?[6-9][0-9]{9}')

const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}

export const id = () => {
    return Math.floor(Math.random() * 100)
}

const FeedbackContainer = ({containerClasses}) => {
    const [feedbackBody, setFeedBackBody] = useState(initValues)
    const [validation, setValidation] = useState(initValidation)
    const [isValidForm, setIsValidForm] = useState(true)

    const handleChange = ({target}) => {
        const name = target.name
        const value = name === 'isGetAnswer' ? target.checked :
            name === 'files' ? [...feedbackBody.files, ...Array.prototype.slice.call(target.files)] : target.value

        setFeedBackBody((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleValidation = (name) => {
        setValidation((prev) => ({
            ...prev,
            [name]: validationMessage
        }))
    }

    const removeFile = (file) => {
        const value = feedbackBody.files.filter(f => f !== file)

        setFeedBackBody((prev) => ({
            ...prev,
            files: value
        }))
    }

    const onSend = async () => {
        setIsValidForm(true)
        if (!isValidEmail(feedbackBody.email)) {
            handleValidation('email')
            setIsValidForm(false)
        }
        if (!isValidPhoneNumber(feedbackBody.phoneNumber)) {
            handleValidation('phoneNumber')
            setIsValidForm(false)
        }
        if (feedbackBody.name === '') {
            handleValidation('name')
            setIsValidForm(false)
        }
        if (feedbackBody.subject === '') {
            handleValidation('subject')
            setIsValidForm(false)
        }
        if (feedbackBody.text === '') {
            handleValidation('text')
            setIsValidForm(false)
        }
        let now = new Date();
        const time = `${now.getHours()}:${now.getMinutes()}`
        const date = `${now.getDate()}.${now.getMonth()}.${now.getFullYear()}`
        const id = nanoid()

        if (isValidForm) {
            const formData = new FormData()
            formData.append('name', feedbackBody.name)
            formData.append('subject', feedbackBody.subject)
            formData.append('text', feedbackBody.text)
            formData.append('email', feedbackBody.email)
            formData.append('phone_number', feedbackBody.phoneNumber)
            formData.append('is_get_answer', feedbackBody.isGetAnswer)
            if (feedbackBody.files.length) {
                feedbackBody.files.forEach(item => formData.append('files', item))
            }
            console.log(formData)
            API.post('api/feedback/', formData)
                .then(res => {
                    alert('Форма успешно отправлена')
                    setFeedBackBody(initValues)
                })
                .catch(e => alert('Ошибка отправки формы'))
        }

        setTimeout(() => {
            setValidation(initValidation)
        }, 4000)
    }

    return (
        <FeedbackUI
            containerClasses={containerClasses}
            classes={styles}
            handleChange={handleChange}
            onSend={onSend}
            feedbackBody={feedbackBody}
            validation={validation}
            removeFile={removeFile}
        />
    )
}

export default FeedbackContainer