import React, {memo} from 'react';

const DirImageUI = () => {
    return (
        <div>
            <svg width="95" height="78" viewBox="0 0 615 616" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M610.406 126.477C606.987 122.654 602.107 120.469 596.982 120.469H526.686V57.6297C526.686 47.663 518.619 39.5828 508.669 39.5828H199.441V18.0469C199.441 8.08019 191.374 0 181.424 0H18.0176C8.06707 0 0 8.08019 0 18.0469L0.0216211 489.615C-0.0108105 493.776 1.38375 497.858 4.05396 501.136C7.47609 505.34 12.6027 507.779 18.0176 507.779H558.189C567.374 507.779 575.087 500.86 576.098 491.716L614.891 140.499C615.454 135.4 613.823 130.301 610.406 126.477ZM36.0352 36.0938H163.406V57.6297C163.406 67.5964 171.473 75.6766 181.424 75.6766H490.651V120.469H90.0927C81.5403 120.469 74.1664 126.491 72.4451 134.882L36.0352 312.304V36.0938ZM542.055 471.688H40.1143L104.783 156.564H576.862L542.055 471.688Z" fill="#353535" />
            </svg>
        </div>
    )
}

export default memo(DirImageUI)
