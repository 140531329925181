import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../../constans/routes'
import SwiperContainer from '../../Swiper/functional/SwiperContainer'

const ObjectCardUI = ({ images, title, content = false, address = false, classes, id, hideLink = false , visuallyImpairedVersion}) => {
    return (
        <>
            { hideLink ? (
                <>
                    <SwiperContainer navigation={false} slidesPerView={1} cards={images} />
                    <div className={visuallyImpairedVersion ? '' : classes['ObjectCard-ContentWrapper']}>
                        <p className={`${classes['ObjectCard-Subtitle']} ${classes['OtherCard-Subtitle--HideLink']}`}>спорткомплекс</p>
                        <p className={`${classes['ObjectCard-Title']} ${classes['OtherCard-Title--HideLink']}`}>{title}</p>
                        {address && <p className={classes['ObjectCard-Adress']}>{address}</p>}
                        {content && <p className={classes['ObjectCard-Content']}>{content}</p>}
                    </div>
                </>
            )
                : (
                    <Link to={`${routes.objects}/${id}`} className={classes['ObjectCard-Link']}>
                        <SwiperContainer navigation={false} slidesPerView={1} cards={images} />
                        <div className={visuallyImpairedVersion ? '' : classes['ObjectCard-ContentWrapper']}>
                            <p className={(address || content) ? classes['ObjectCard-Subtitle'] : classes['OtherCard-Subtitle']}>спорткомплекс</p>
                            <p className={(address || content) ? classes['ObjectCard-Title'] : classes['OtherCard-Title']}>{title}</p>
                            {address && <p className={classes['ObjectCard-Adress']}>{address}</p>}
                            {content && <p className={classes['ObjectCard-Content']}>{content}</p>}
                        </div>
                    </Link>
                )
            }
        </>
    )
}

export default ObjectCardUI