import { useEffect } from 'react'
import API from '../api/api';

export const useFetchData = (dataList, url, setData) => {

    return useEffect(() => {
        let didCancel = false; // abort data fetching 
        
        if (!dataList) {
            const fetchData = async () => {
            try {
                const result = await API.get(url);
                if (!didCancel) {
                    setData(result.data);
                }
            }
            catch (er) { console.error('error', er); }
            }
            
            fetchData();
        }
        window.scrollTo(0, 0);
        
        return () => {
            didCancel = true;
        }
    }, [dataList, url, setData]);

}
//dataList, url, setData