import { initialState, setGlobalState } from './state';

export const showForVisuallyImpairedVersion = () => {
    setGlobalState('visuallyImpairedVersion', oldState => ({ ...oldState, showVisuallyImpairedVersion: true}));
    window.scrollTo(0, 0);
};

export const hideForVisuallyImpairedVersion = () => {
    setGlobalState('visuallyImpairedVersion', () => ({ ...initialState.visuallyImpairedVersion }));
};

export const setActiveTheme = (theme) => {
    setGlobalState('visuallyImpairedVersion', oldData => ({
        ...oldData, 
        activeTheme: theme,  
      }));
};

export const setActiveFontSize = (fontSize) => {
    setGlobalState('visuallyImpairedVersion', oldData => ({
        ...oldData, 
        activeFontSize: fontSize,  
      }));
};