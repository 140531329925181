import WithLoading from "../../../hoc/withLoading";
import ContentContainer from "../../Content/functional/ContentContainer";
import FooterContainer from "../../Footer/functional/FooterContainer";
import ForVisuallyImpairedContainer from "../../ForVisuallyImpaired/functional/ForVisuallyImpairedContainer";
import HeaderContainer from "../../Header/functional/HeaderContainer";
import ModalContainer from "../../Modal/functional/ModalContainer";

const AppUI = ({visuallyImpairedVersion, activeTheme, activeFontSize}) => {
  return (
    <div className={`${visuallyImpairedVersion ? 'App App--VisuallyImpairedVersion': 'App'} ${activeTheme && activeTheme } ${activeFontSize && activeFontSize}`}>
      {visuallyImpairedVersion && <ForVisuallyImpairedContainer />}
      <HeaderContainer />
      <ContentContainer />
      <FooterContainer />
      <ModalContainer />
    </div>
  );
}

export default WithLoading(AppUI);
