import React, { useMemo } from 'react'
import { setCurrentOtherDocsPage, setOtherDocsOffsetState } from '../../../../../../../state/docsState';
import { useGlobalState } from '../../../../../../../state/state';
import DocFileContainer from '../../../../../../common/DocFile/functional/DocFileContainer';
import OtherDocsUI from '../ui/OtherDocsUI';
import styles from './OtherDocs.module.css';

const OtherDocsContainer = () => {
    const [{ otherDocsList, otherDocsOffset, otherDocsPerPage, currentOtherDocsPage, otherDocsCount }] = useGlobalState('docs');

    const mapOtherDocsFiles = useMemo(() => ()=>{
        return otherDocsList?.map( (el, i) => <DocFileContainer key={i} href={el.file} text={el.name} /> )
    }, [otherDocsList]);

    const handlePageClick = ({ selected: selectedPage }) => {
        // const selectedPage = e.selected;
        setCurrentOtherDocsPage(selectedPage);
        const newOffset = selectedPage * otherDocsPerPage;
        setOtherDocsOffsetState(newOffset);
    };

    const currentPageData = otherDocsList ? mapOtherDocsFiles().slice(otherDocsOffset, otherDocsOffset + otherDocsPerPage) : [];

    return (
        <div>
            <OtherDocsUI classes={styles} docs={currentPageData} handlePageClick={handlePageClick} currentPage={currentOtherDocsPage} count={otherDocsCount} />
        </div>
    )
}

export default OtherDocsContainer
