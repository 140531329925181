import React, { useCallback } from 'react'
import CardUI from '../ui/CardUI';
import styles from './Card.module.css';
 
const CardContainer = ({content, margin}) => {

    const windowToTop = useCallback(()=>window.scrollTo(0, 0), [])

    return <CardUI content={content} classes={styles} margin={margin} onClick={windowToTop} />
}
 
export default CardContainer