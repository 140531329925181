import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { useGlobalState } from '../../../../../state/state';
import PressCenterIdUI from '../ui/PressCenterIdUI';
import styles from './PressCenterId.module.css';
import {setNewsContent} from '../../../../../state/newsState';
import PressCenterCardContainer from '../../../../common/PressCenterCard/functional/PressCenterCardContainer';

const PressCenterIdContainer = () => {

    const { pressCenterId } = useParams();

    const [{ newsList }] = useGlobalState('news');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    useFetchData(newsList, urls.pressCenter, setNewsContent);
 
    const shortNewsList = useMemo(()=>{
        return newsList ? newsList.slice(newsList.length-3, newsList.length) : [];
    }, [newsList])
 
    const filteredNews = useMemo(() => () => {
 
        let filteredObject = newsList?.filter( el => el.id === Number(pressCenterId));
         
         return filteredObject.map( el => (
            <PressCenterCardContainer key={el.id} id={el.id} content={el.content} title={el.title} image={el.images} hideLink />
        ))
    }, [newsList, pressCenterId])
 
    const currentNewsCard = newsList ? filteredNews() : [];
 
    const mapNewsCards = useMemo(() => ()=>{
        return shortNewsList?.map( el => <PressCenterCardContainer key={el.id} id={el.id} title={el.title} content={el.content} />)
    }, [shortNewsList])
 
    const newsCards = shortNewsList ? mapNewsCards() : [];

    return (
        <PressCenterIdUI newsCards={newsCards} 
                         newsCard={currentNewsCard} 
                         classes={styles} 
                         data={newsList} 
                         dataDep={[newsList]}
                         visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}

export default PressCenterIdContainer
