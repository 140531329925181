import React from 'react'
import PaginateContainer from '../../../../../../common/Paginate/functional/PaginateContainer';
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer';

const OtherDocsUI = ({ docs, classes, handlePageClick, count, currentPage }) => {
    return (
        <div>
            <TitleContainer text='ИНЫЕ ДОКУМЕНТЫ' />
            <div className={classes['Container']}>
                {docs}
            </div>
            <PaginateContainer count={count} handlePageClick={handlePageClick} currentPage={currentPage} />
        </div>
    )
}

export default OtherDocsUI
