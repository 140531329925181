import React from 'react';
import {
    YMaps,
    Map,
    TrafficControl,
    ZoomControl,
    GeolocationControl,
    TypeSelector,
    FullscreenControl
} from 'react-yandex-maps';
import styles from '../../../Content/functional/AboutSection/functional/AboutSection.module.css';
import TitleContainer from '../../Title/functional/TitleContainer';
import banner from '../../../../images/banner.jpg';

const ObjectsMapUI = ({mapData, placemarks, active}) => {
    return (
        <>
            {active && <section>
                <TitleContainer text='ОБЪЕКТЫ НА КАРТЕ'/>
                <YMaps>
                    <Map width='100%' height='550px' defaultState={mapData}>
                        {placemarks}
                        <TrafficControl options={{float: 'right'}}/>
                        <ZoomControl options={{float: 'right'}}/>
                        <GeolocationControl options={{float: 'left'}}/>
                        <TypeSelector options={{float: 'right'}}/>
                        <FullscreenControl options={{float: 'right'}}/>
                    </Map>
                </YMaps>
            </section>}
        </>

    )
}

export default ObjectsMapUI
