import React, { useEffect, useMemo } from 'react';
import { setNewsActiveState } from '../../../../../../../state/newsState';
import { useGlobalState } from '../../../../../../../state/state';
import { SwiperSlide } from 'swiper/react';
import styles from './PressCenterSection.module.css';
import PressCenterCardContainer from '../../../../../../common/PressCenterCard/functional/PressCenterCardContainer';
import PressCenterSectionUI from '../ui/PressCenterSectionUI';
import CardContainer from '../../../../../../common/Card/functional/CardContainer';
import { sections } from '../../../../../../../constans/sections';
 
const PressCenterSectionContainer = () => {
 
    const [{ newsList, activeNewsSection }] = useGlobalState('news');
    const [{ sectionsList }] = useGlobalState('sections');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    useEffect(()=>{
        if(sectionsList){
            setNewsActiveState(sectionsList, sections.pressCenter);
        }
    },[sectionsList]);
 
    const mapNewsCards = useMemo(() => ()=>{
        return newsList?.map( el => {
            return showVisuallyImpairedVersion ?    
         (<CardContainer content={<PressCenterCardContainer id={el.id} title={el.title} content={el.content} image={el.images} />} />)   
         :
         (<SwiperSlide key={el.id} className={styles.Slide}>
            <CardContainer content={<PressCenterCardContainer id={el.id} title={el.title} content={el.content} image={el.images} />} />
        </SwiperSlide>)
        }
        )
    }, [newsList, showVisuallyImpairedVersion])
 
    const newsCards = newsList ? mapNewsCards() : [];
 
    return <PressCenterSectionUI cards={newsCards} 
                                 classes={styles}
                                 slidesPerView='3' 
                                 title={sections.pressCenter} 
                                 active={activeNewsSection}
                                 visuallyImpairedVersion={showVisuallyImpairedVersion} />
}
 
export default PressCenterSectionContainer