import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import ObjectsMapContainer from '../../../../common/ObjectsMap/functional/ObjectsMapContainer';
import ObjectsContainer from '../functional/Objects/functional/ObjectsContainer';

const ObjectsPageUI = () => {
    return (
        <div className='Wrapper'>
            <ObjectsContainer />
            <ObjectsMapContainer />
        </div>
    )
}

export default WithLoading(ObjectsPageUI)
