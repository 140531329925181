import React from 'react'
import { swiperBreakpoints } from '../../../../../../../constans/swiper'
import SwiperContainer from '../../../../../../common/Swiper/functional/SwiperContainer'
import TitleContainer from '../../../../../../common/Title/functional/TitleContainer'

const PressCenterSectionUI = ({ cards, slidesPerView, title, active, visuallyImpairedVersion, classes }) => {

    return (
        <>
            {active && <section className={visuallyImpairedVersion ? 'Wrapper' : ''}>
                <TitleContainer text={title} />
                {
                    visuallyImpairedVersion ?
                    (
                        <div className={classes['Container--VisuallyImpairedVersion']}>
                        {cards}
                        </div>)
                    :
                    (<SwiperContainer breakpoints={swiperBreakpoints}
                        cards={cards}
                        slidesPerView={slidesPerView}
                        navigation={true}
                        className='Container' />)
                }
            </section>
            }
        </>
    )
}

export default PressCenterSectionUI
