import React from 'react';
import FileImageUI from '../ui/FileImageUI';
import styles from './FileImage.module.css';

const FileImageContainer = () => {
    return (
        <FileImageUI classes={styles} />
    )
}

export default FileImageContainer
