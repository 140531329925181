import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import ObjectsMapContainer from '../../../../common/ObjectsMap/functional/ObjectsMapContainer';

const ObjectIdUI = ({ otherCards, objectCard, classes, visuallyImpairedVersion }) => {
    return (
        <div>
        <section className={visuallyImpairedVersion ? 'Wrapper' : '' }>
            <div className={visuallyImpairedVersion ? classes['Container--VisuallyImpairedVersion'] : classes['Container']}>
                <div>
                    {objectCard}
                </div>
                <div className={classes['OtherCards-Wrapper']}>
                    <p className={classes['OtherCards-Title']}>ДРУГИЕ ОБЪЕКТЫ:</p>
                    {otherCards}
                </div>
            </div>
        </section>
        <ObjectsMapContainer />
        </div>
    )
}
 
export default WithLoading(ObjectIdUI)