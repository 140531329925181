import React, { useMemo } from 'react';
import { setCurrentObjectsPage, setObjectsOffsetState } from '../../../../../../../state/objectsState';
import { useGlobalState } from '../../../../../../../state/state';
import CardContainer from '../../../../../../common/Card/functional/CardContainer';
import ObjectCardContainer from '../../../../../../common/ObjectCard/functional/ObjectCardContainer';
import ObjectsUI from '../ui/ObjectsUI';
import styles from './Objects.module.css';

const ObjectsContainer = () => {

    const [{ objectsList, objectsOffset, objectsCount,  currentObjectsPage, objectsPerPage}] = useGlobalState('objects');
    const [{ showVisuallyImpairedVersion }] = useGlobalState('visuallyImpairedVersion');
 
    const mapObjects = useMemo(() => ()=>{
        return objectsList?.map( el => (
            <CardContainer key={el.id} content={ <ObjectCardContainer id={el.id} title={el.title} address={el.address} image={el.images} />} />
        ))
    }, [objectsList])

    const handlePageClick = ({ selected: selectedPage }) => {
        // const selectedPage = e.selected;
        setCurrentObjectsPage(selectedPage);
        const newOffset = selectedPage * objectsPerPage;
        setObjectsOffsetState(newOffset);
    };

    const currentObjectsData = objectsList ? mapObjects().slice(objectsOffset, objectsOffset + objectsPerPage) : [];

    return (
        <ObjectsUI objectsCards={currentObjectsData} 
                   count={objectsCount} 
                   handlePageClick={handlePageClick} 
                   currentPage={currentObjectsPage} 
                   classes={styles}
                   visuallyImpairedVersion={showVisuallyImpairedVersion} />
    )
}

export default ObjectsContainer
