import React from 'react'
import { closeMenu, openMenu } from '../../../state/menuState';
import MosSportLogoContainer from '../../common/logo/mosSportLogo/functional/MosSportLogoContainer';
import NavigationContainer from '../../Navigation/functional/NavigationContainer';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { routes } from '../../../constans/routes';
import { urls } from '../../../constans/urls';
import img from '../../../images/logoMoss.png';

const HeaderUI = ({ mobileScreen, show, classes, headerItems, visuallyImpairedVersion }) => {
    return (
        <>
            {
                mobileScreen ? (
                    <>
                        { visuallyImpairedVersion ?
                            <div className={visuallyImpairedVersion ? classes['Underline--VisuallyImpairedVersion'] : ''}>
                                <section className='Wrapper'>
                                    <NavigationContainer items={headerItems} className='Column' close={() => closeMenu()} />
                                </section>
                            </div>
                            :
                            <div className={show ? classes['Container'] : ''}>
                                <header className={classes['Menu-Wrapper']}>
                                    <div className={visuallyImpairedVersion ? classes['Menu--VisuallyImpairedVersion'] : classes['Menu']}>
                                        {visuallyImpairedVersion ?
                                            (<Link to={routes.main} className={classes['Main-Link']}>
                                                АНО Мосспортразвитие
                                            </Link>)
                                            :
                                            (<Link to={routes.main} className={classes['Main-Link']}>
                                                <MosSportLogoContainer />
                                            </Link>)
                                        }
                                        {show ?
                                            <FaTimes className={classes['Menu-Burger']} onClick={() => closeMenu()} />
                                            :
                                            <FaBars className={classes['Menu-Burger']} onClick={() => openMenu()} />}
                                    </div>
                                </header>
                                {show && <> <NavigationContainer items={headerItems} className='Column' close={() => closeMenu()} />
                                    {!visuallyImpairedVersion && <a className={classes['Main-MossIcon']} target='_blank' rel='noreferrer' href={urls.depMosSport}><img src={img} alt='Logo' /></a>}
                                </>
                                }
                            </div>}
                    </>
                )
                    : (<header className={classes['Menu-Wrapper']}>
                        <div className={visuallyImpairedVersion ? classes['Menu--VisuallyImpairedVersion'] : classes['Menu']}>
                            {visuallyImpairedVersion ?
                                (<Link to={routes.main} className={classes['Main-Link']}>
                                    <h5>АНО Мосспортразвитие</h5>
                                </Link>)
                                :
                                (<Link to={routes.main} className={classes['Main-Link']}>
                                    <MosSportLogoContainer />
                                </Link>)
                            }
                            <div className={visuallyImpairedVersion ? classes['Underline--VisuallyImpairedVersion'] : ''}>
                                <NavigationContainer items={headerItems} className='Row' />
                            </div>
                            {!visuallyImpairedVersion && <a className={classes['Main-MossIcon']} target='_blank' rel='noreferrer' href={urls.depMosSport}><img src={img} alt='Logo' /></a>}
                        </div>
                    </header>
                    )
            }
        </>
    )
}

export default HeaderUI
