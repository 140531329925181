import React from 'react'
import SwiperContainer from '../../../../../../common/Swiper/functional/SwiperContainer'

const BannerUI = ({ cards, slidesPerView }) => {

    return (
        <SwiperContainer className='Swiper-Container' cards={cards} slidesPerView={slidesPerView} navigation={true} />
    )
}

export default BannerUI
