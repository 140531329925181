import React from 'react';
import { urls } from '../../../../../constans/urls';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { setObjectsContent } from '../../../../../state/objectsState';
import { useGlobalState } from '../../../../../state/state';
import ObjectsPageUI from '../ui/ObjectsPageUI';

const ObjectsPageContainer = () => {

    const [{ objectsList }] = useGlobalState('objects');
    useFetchData(objectsList, urls.objects, setObjectsContent);

    return (
        <ObjectsPageUI data={objectsList} dataDep={[objectsList]} />
    )
}

export default ObjectsPageContainer
