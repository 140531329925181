import { setGlobalState } from './state';

export const setDocsDescriptionsContent = data => {

    const newDocsDescriptionsList = data || [];
 
    setGlobalState('docsDescriptions', oldData => ({
      ...oldData, 
      docsDescriptionsList: [ ...(oldData.docsDescriptionsList || []), ...newDocsDescriptionsList ],
      desc_docs_1: data[0]?.desc_docs_1 || '',
      desc_docs_2: data[0]?.desc_docs_2 || '',
      desc_procurement: data[0]?.desc_procurement || '',
    }));
};