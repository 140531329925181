import React from 'react';
import WithLoading from '../../../../../hoc/withLoading';
import ContactsSectionContainer from '../../ContactsSection/functional/ContactsSectionContainer';
import AboutUsContainer from '../functional/AboutUs/functional/AboutUsContainer';

const AboutPageContainer = () => {
    return (
        <div className='Wrapper'>
            <AboutUsContainer />
            <ContactsSectionContainer subtitle='Контакты' />
        </div>
    )
}

export default WithLoading(AboutPageContainer)
