import React from 'react'
import WithLoading from '../../../../../hoc/withLoading';
import ActionTypeSelectContainer from '../functional/ActionTypeSelect/functional/ActionTypeSelectContainer';

const DocsPageUI = ({ RenderComponent, setActionType, actionType }) => {

    return (
        <div className='Wrapper'>
            <section>
                <ActionTypeSelectContainer setActionType={setActionType} actionType={actionType} />
                <div >
                    {RenderComponent && <RenderComponent />}
                </div>
            </section>
        </div>
    )
}

export default WithLoading(DocsPageUI)
