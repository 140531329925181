import { setGlobalState } from "./state";

export const setDocsContent = (data) => {
  let newDocsList = [...data] || [];

  let newPurchaseList = newDocsList.filter(
    (file) => file.type === "procurement"
  );

  function filterArr(arrList, newArr) {
    arrList.forEach(
      (el) =>
        el.folder
          ? newArr.push(
              arrList.filter((file) => {
                return file.folder && file.folder.id === el.folder.id; // находим все одинаковые папки по folder id
              })
            )
          : newArr.push(el) // если нет папки, то добавляем файл
    );
    const unique = [...new Set(newArr.map((o) => JSON.stringify(o)))].map(
      (string) => JSON.parse(string)
    ); // удаляем повторяющиеся массивы

    return unique;
  }

  let uniqList = filterArr(newPurchaseList, []);

  setGlobalState("docs", (oldData) => ({
    ...oldData,
    docsList: [...(oldData.docsList || []), ...newDocsList],
    mainDocsList: newDocsList.filter((file) => file.type === "documents") || [],
    purchaseList: uniqList || [],
    otherDocsList:
      newDocsList.filter((file) => file.type === "other_documents") || [],
    purchaseCount: Math.ceil(uniqList.length / oldData.purchasePerPage) || 0,
    otherDocsCount:
      Math.ceil(oldData.otherDocsList.length / oldData.otherDocsPerPage) || 0,
  }));
};

export const setCurrentPurchasePage = (currentPage) => {
  setGlobalState("docs", (oldData) => ({
    ...oldData,
    currentPurchasePage: currentPage,
  }));
};

export const setPurchaseOffsetState = (offset) => {
  setGlobalState("docs", (oldData) => ({
    ...oldData,
    purchaseOffset: offset,
  }));
};

export const setCurrentOtherDocsPage = (currentPage) => {
  setGlobalState("docs", (oldData) => ({
    ...oldData,
    currentOtherDocsPage: currentPage,
  }));
};

export const setOtherDocsOffsetState = (offset) => {
  setGlobalState("docs", (oldData) => ({
    ...oldData,
    otherDocsOffset: offset,
  }));
};
