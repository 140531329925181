import React from 'react';
import { urls } from '../../../../../constans/urls';
import TitleContainer from '../../../../common/Title/functional/TitleContainer';
import MapContainer from '../functional/Map/functional/MapContainer';
import FeedbackContainer from '../functional/Feedback/functional/FeedbackContainer';
 
const ContactsSectionUI = ({classes, adress, workTime, phoneNumber, email, logo, title, subtitle, visuallyImpairedVersion}) => {
    return (
        <section className={visuallyImpairedVersion ? 'Wrapper' : ''}>
            { title && <TitleContainer text={title} /> }
            <div className={visuallyImpairedVersion ? classes['Container--VisuallyImpairedVersion'] : classes['Container']}>
                {!visuallyImpairedVersion && <div className={classes['Map']}>
                    <MapContainer />
                </div>}
                <div className={classes['SupportedBy']}>
                    <p className={`${classes['Subtitle']} ${classes['Subtitle-SupportedBy']}`}>При поддержке</p>
                    {visuallyImpairedVersion ? <a target='_blank' rel='noreferrer' href={urls.depMosSport}>Департамент спорта города Москвы</a>
                     : <img src={logo} alt='Logo' />}
                </div>
                <div className={classes['Contacts']}>
                    { subtitle && <p className={`${classes['Subtitle']} ${classes['Subtitle-Contacts']}`}>Контакты</p>}
                    <div className={classes['Contacts-Item']}>
                        <p className={classes['Contacts-Item--Subtitle']}>Адрес</p>
                        <p>{adress}</p>
                    </div>
                    <div className={classes['Contacts-Item']}>
                        <p className={classes['Contacts-Item--Subtitle']}>Время работы</p>
                        <p className={classes['Contacts-Item--Work-Time']}>{workTime}</p>
                    </div>
                    <div className={classes['Contacts-Item']}>
                        <p className={classes['Contacts-Item--Subtitle']}>Номер телефона</p>
                        <p>{phoneNumber}</p>
                    </div>
                    <div className={classes['Contacts-Item']}>
                        <p className={classes['Contacts-Item--Subtitle']}>E-mail</p>
                        <p>{email}</p>
                    </div>
                </div>
                <div className={classes['Feedback']}>
                    <FeedbackContainer containerClasses={classes} />
                </div>
            </div>
        </section>
    )
}
 
export default ContactsSectionUI