import React, { memo } from 'react'

const MapUI = ({ href }) => {
    return (
            <iframe src={href}
                width="100%"
                frameBorder="0"
                scrolling="no"
                title="Документы из автосалона"
                loading="lazy"
                height="100%" />
    )
}

export default memo(MapUI)
